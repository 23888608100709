@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');

.bg {
    background-color: rgb(224, 224, 224);
}

.style {
    font-size: xx-large;
    font-family: 'Ubuntu', sans-serif;
}
